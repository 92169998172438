<template>
  <iframe
    class="iframe-5000"
    src="http://interface.sbds.io:5000/"
  >
  </iframe>
</template>

<script>
export default {
  name: 'Interface5000',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {
    this.$emit('authentified')
  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
.iframe-5000 {
  height: 100%;
}
</style>
